import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Clean Grip Deadlifts 4×6\\@75% `}<em parentName="p">{`Conventional Deadlift 1RM`}</em></p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`15-Calorie Assault Bike`}</p>
    <p>{`15-SDHP’s (95/65)`}</p>
    <p>{`15-T2B`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      